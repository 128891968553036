import React from 'react';
import { Badge } from '@GDM/Badge';
import { CellContext } from '@tanstack/react-table';
import { FieldValues } from 'react-hook-form';

export const EnumCell = <T extends FieldValues>({ getValue }: CellContext<T, unknown>) => {
  const value = getValue<string[] | number[]>();

  if (!value) {
    return null;
  }

  return (
    <div className="d-flex gap-2">
      {value.map((v) => (
        <Badge key={v} label={`${v}`} />
      ))}
    </div>
  );
};
