import React, { useMemo } from 'react';
import { FormType } from '@GDM/Table/DataTable/DataTable.types';
import useBooks from '@hooks/requests/useBooks';
import Book from '@utils/types/book';
import Installation from '@utils/types/installation';
import type { Unavailability } from '@utils/types/unavailability';
import { AvailabilityCards } from './AvailabilityCards';
import { AvailabilityChart } from './AvailabilityChart';
import { computeTotalPmax } from './utils/computeAvailabilities';
import { computeAvailabilities } from './utils/computeAvailabilities';
import { useUnavailabilitiesInstallations } from './utils/useUnavailabilitiesInstallations';

export const renderHeader = (startDate: Date | null, endDate: Date | null, chartVisible: boolean) => {
  return ({ filteredData, filters }: { filteredData: Unavailability[]; filters: FormType }) => {
    const installationsQuery = useUnavailabilitiesInstallations();
    const booksQuery = useBooks();

    const installations = useMemo(() => installationsQuery.data || [], [installationsQuery.data]);
    const books = useMemo(() => booksQuery.data || [], [booksQuery.data]);

    const isLoading = installationsQuery.isPending || booksQuery.isPending;

    const filteredInstallations = useMemo(
      () => filterInstallations(filters, installations, books),
      [filters, books, installations],
    );

    const totalPMax = useMemo(() => {
      return computeTotalPmax(filteredInstallations);
    }, [filteredInstallations]);

    const availabilities = useMemo(() => {
      return computeAvailabilities(filteredData, totalPMax, startDate, endDate);
    }, [filteredData, totalPMax]);

    return (
      <div className="d-flex">
        {chartVisible && <AvailabilityChart availabilities={availabilities} />}

        <AvailabilityCards
          availabilities={availabilities}
          total_p_max={totalPMax}
          chartVisible={chartVisible}
          isLoading={isLoading}
        />
      </div>
    );
  };
};

const filterInstallations: (filters: FormType, installations: Installation[], books: Book[]) => Installation[] = (
  filters,
  installations,
  books,
) => {
  if (!installations) return [];

  const energy = filters['installation_energy'];
  const installationNames = filters['installation_name'];
  const bookNames = filters['installation_books'];

  return installations.filter((installation) => {
    if (energy != 'all' && installation.energy != energy) {
      return false;
    }
    if (
      installationNames &&
      Array.isArray(installationNames) &&
      installationNames.length > 0 &&
      !installationNames.includes(installation.name)
    ) {
      return false;
    }
    if (
      bookNames &&
      Array.isArray(bookNames) &&
      bookNames.length > 0 &&
      !books?.find((b) => bookNames.includes(b.name))?.installation_names?.includes(installation.name)
    ) {
      return false;
    }

    return true;
  });
};
