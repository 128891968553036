import React from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { Badge } from '@GDM/Badge';
import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';
import { isCurrency } from '@utils/isCurrency';
import type Book from '@utils/types/book';
import Installation from '@utils/types/installation';
import { FieldValues } from 'react-hook-form';
import { CurrencyCell, DateCell, NumberCell } from '../Cells';
import { CollectionCell } from '../Cells/CollectionCell';
import { DateRangeCell } from '../Cells/DateRangeCell';
import { EnumCell } from '../Cells/EnumCell';
import { StringCell } from '../Cells/StringCell';
import { Column, ColumnType } from './DataTable.types';

export function getCell<T extends FieldValues>(column: Column<T>) {
  if (column.customCell) {
    return column.customCell;
  }

  const getMap = (unit: Column<T>['unit']): Record<ColumnType, ColumnDefTemplate<CellContext<T, unknown>>> => ({
    date: (props) => <DateCell value={props.getValue<string | number | Date>()} format="YYYY-MM-DD" />,
    datetime: (props) => <DateCell value={props.getValue<string | number | Date>()} />,
    daterange: DateRangeCell,
    string: (props) => <StringCell {...props} I18NPrefix={column.I18NPrefix} />,
    enum: EnumCell,
    number: NumberCell,
    currency: (props) => <CurrencyCell value={props.getValue<number>()} currency={isCurrency(unit) ? unit : 'EUR'} />,
    energy: (props) => props.getValue<number>(),
    custom: (props) => props.getValue<React.ReactNode>(),
    installation: ({ getValue, row: { original } }) => {
      if (!isInstallation(original.installation)) {
        return getValue<string>();
      }

      return (
        <MeterNameCell
          hasMeter={original.installation.has_meter}
          installation_name={original.installation.name}
          installation_energy={original.installation.energy}
        />
      );
    },
    collection: CollectionCell,
    book: (props) => {
      const books = props.getValue<Book[]>();

      if (!books || !books.length) {
        return null;
      }

      return (
        <div className="d-flex gap-2">
          {books.map((book) => (
            <Badge label={book.name} key={book.uuid} />
          ))}
        </div>
      );
    },
    action: (props) => props.getValue<React.ReactNode>(),
  });

  return getMap(column.unit)[column.dataType];
}

const isInstallation = (installation: unknown): installation is Installation => {
  return (
    typeof installation === 'object' &&
    installation !== null &&
    'has_meter' in installation &&
    'name' in installation &&
    'energy' in installation
  );
};
