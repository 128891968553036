import { useCallback } from 'react';
import { energyOptions } from '@utils/constants/energyOptions';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type Book from '@utils/types/book';
import { EnergyType, Option } from '@utils/types/common-types';
import get from 'lodash/get';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import { Column } from '../DataTable.types';

export function useGetOptions<T extends FieldValues>(column: Column<T>) {
  const path = column.accessorKey as Path<T>;

  return useCallback(
    (data: T[]) => {
      if (column.customOptions) {
        return column.customOptions.sort(sortOptionsByLabelAsc);
      }

      if (column.dataType === 'energy') {
        return energyOptions as Option<PathValue<T, Path<T>>>[];
      }

      const map = new Map<PathValue<T, Path<T>>, Option<PathValue<T, Path<T>>> & { energy: EnergyType }>();

      data.forEach((currentData: T) => {
        const value = get(currentData, path);

        if (['book', 'collection'].includes(column.dataType) && Array.isArray(value)) {
          value.forEach((v: Book) => {
            const option = getOption(column, v.name as PathValue<T, Path<T>>);

            if (option) map.set(option.key, option.option);
          });

          return;
        }

        const option = getOption(column, value);

        if (option) map.set(option.key, option.option);
      });

      return Array.from(map.values()).sort(sortOptionsByLabelAsc);
    },
    [path, column],
  );
}

function getOption<T extends FieldValues>(
  column: Column<T>,
  value: PathValue<T, Path<T>>,
): null | {
  key: PathValue<T, Path<T>>;
  option: Option<PathValue<T, Path<T>>> & { energy: EnergyType };
} {
  if (!value) {
    return null;
  }

  return {
    key: value,
    option: {
      label: column.I18NPrefix ? `${column.I18NPrefix}.${value as string}` : (value as string),
      value: value,
      energy: column.dataType === 'book' ? 'book' : get(value, 'installation.energy'),
    },
  };
}
