import React from 'react';
import { useUser } from '@context/User.context';
import { Icon } from '@GDM/Icon';
import { Text } from '@GDM/Text';
import { tString } from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { formatDate } from '@utils/formatParseDate';
import { Locale } from '@utils/types/common-types';
import dayjs from 'dayjs';
import type { Duration } from 'dayjs/plugin/duration';
import { FieldValues } from 'react-hook-form';

export const DateRangeCell = <T extends FieldValues>({ row: { original } }: CellContext<T, unknown>) => {
  const { locale } = useUser();

  const start = original.start_date || original.start_time;
  const end = original.end_date || original.end_time;

  if (!start || !end) {
    return null;
  }

  const duration = formatDuration(dayjs.duration(dayjs(end).diff(start)), locale);

  return (
    <div>
      <div className="d-flex gap-2 align-items-center">
        {formatDate(new Date(start), 'YYYY-MM-DD HH:mm')}
        <Icon name="ArrowRight" size={14} />
        {formatDate(new Date(end), 'YYYY-MM-DD HH:mm')}
      </div>

      <Text className="text-grey fw:400" text={duration} />
    </div>
  );
};

const formatDuration = (duration: Duration, locale: Locale) => {
  const t = tString(locale);
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();

  const translationStr = [
    days === 1 && 'day',
    days > 1 && 'days',
    hours === 1 && 'hour',
    hours > 1 && 'hours',
    minutes === 1 && 'minute',
    minutes > 1 && 'minutes',
  ]
    .filter(Boolean)
    .map((key) => t(`common.duration.${key}`, { days, hours, minutes }))
    .join(' ');

  return translationStr;
};
