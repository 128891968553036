import React, { useCallback, useState } from 'react';
import DownloadCsvButton from '@components/invoices/Actions/ActionsButtons/DownloadCsvButton';
import DownloadPdfButton from '@components/invoices/Actions/ActionsButtons/DownloadPdfButton';
import { Restricted } from '@components/Restricted';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { useUser } from '@context/User.context';
import { Button, DropDownButton } from '@GDM/Button';
import { SelectAllCheckBox } from '@GDM/forms/Checkbox';
import { Icon } from '@GDM/Icon';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import { usePermittedStatus } from '@hooks/requests/invoices/usePermittedStatus';
import { useDownloadFecReport } from '@hooks/requests/meter-invoices/useDownloadFecReport';
import { useDownloadWinshuttleReport } from '@hooks/requests/meter-invoices/useDownloadWinshuttleReport';
import useTranslation from '@hooks/useTranslation';
import { Row } from '@tanstack/react-table';
import { filterRemitInvoices } from '@utils/contract/filterRemit';
import type { Contract } from '@utils/types/contract';
import { MeterInvoice, MeterInvoiceSource } from '@utils/types/meter-invoice';
import classNames from 'classnames';
import fileDownload from 'js-file-download';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import remitCsvExport from 'pages/sales-management/invoicing/utils/csvExports/remitCsvExport';
import styles from './actions.module.scss';
import ApproveInvoicesButton from './ActionsButtons/ApproveInvoicesButton';
import { DownloadAuditCsvButton } from './ActionsButtons/DownloadAuditCsvButton';
import GenerateFinalInvoicesButton from './ActionsButtons/GenerateFinalInvoicesButton';
import IgnoreButton from './ActionsButtons/IgnoreButton';
import { NominateCegedimButton } from './ActionsButtons/NominateCegedimButton';
import { NominateEdfOaButton } from './ActionsButtons/NominateEdfOaButton';
import { PaymentButton } from './ActionsButtons/PaymentButton';
import PendingButton from './ActionsButtons/PendingButton';
import SendEmailButton from './ActionsButtons/SendEmailButton';

const isAcceptedExternalService =
  (filteredInvoices: MeterInvoice[]) =>
  (uuid: string): boolean => {
    const invoice = filteredInvoices.find((m) => m.uuid === uuid);
    if (invoice?.status === 'accepted_external_service') return true;

    return false;
  };

export const Actions = ({
  setIsTableExpanded,
  isTableExpanded,
  invoiceType,
  startDate,
  endDate,
  toggleEmailModal,
  toggleNominateCegedimModal,
  toggleNominateEdfOaModal,
  page,
  className,
  direction,
  showDetailsButton,
}: {
  setIsTableExpanded?: (state: boolean) => void;
  isTableExpanded: boolean;
  invoiceType: MeterInvoiceSource;
  startDate?: Date | null;
  endDate?: Date | null;
  toggleEmailModal?: () => void;
  toggleNominateCegedimModal?: () => void;
  toggleNominateEdfOaModal?: () => void;
  page: Row<MeterInvoice>[];
  className?: string;
  direction: Contract['direction'] | 'all';
  showDetailsButton: boolean;
}) => {
  const { t } = useTranslation();
  const user = useUser();
  const { filteredInvoices, allInvoices } = useInvoicesContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { selectedInvoicesUuids, selectMultipleInvoices, unselectAllInvoices } = useSelectedInvoicesContext();
  const allUuids = page.map((d) => d.original?.uuid);
  const { disabled: disabledGenerated } = usePermittedStatus('generated');
  const { disabled: disabledNomination } = usePermittedStatus('requested_submission_external_service');
  const { disabled: disabledPending } = usePermittedStatus('pending');
  const { disabled: disabledIgnored } = usePermittedStatus('ignored');
  const downloadFecReportRequest = useDownloadFecReport({ startDate, endDate });
  const downloadWinshuttleReportSelected = useDownloadWinshuttleReport({
    startDate,
    endDate,
    invoice_uuids_to_filter: selectedInvoicesUuids?.length > 0 ? selectedInvoicesUuids : null,
  });

  const downloadWinshuttleReportFiltered = useDownloadWinshuttleReport({
    startDate,
    endDate,
    invoice_uuids_to_filter: filteredInvoices?.length > 0 ? filteredInvoices.map((i) => i.uuid) : null,
  });

  const handleExportRemitClick = () => {
    const fileName = 'Remit_Table_Trades.csv';
    const filteredInvoices = allInvoices.filter(filterRemitInvoices);
    fileDownload(remitCsvExport(filteredInvoices), fileName);
  };

  const handleSendEmail = useCallback(() => {
    toggleEmailModal?.();
  }, [toggleEmailModal]);

  const acceptedExternalService = selectedInvoicesUuids.filter(isAcceptedExternalService(filteredInvoices));

  const selectedInvoices = filteredInvoices.filter((invoice) => selectedInvoicesUuids.includes(invoice.uuid));

  const areAllSelectedInvoicesFrench = selectedInvoices.every((invoice) => invoice.installation_country === 'FR');

  return (
    <div className={classNames(styles.actions, 'mb-2', className)}>
      <div className="d-flex justify-content-start align-items-center">
        <SelectAllCheckBox
          name="select_all_invoices"
          onChange={(e) =>
            e.currentTarget.checked ? selectMultipleInvoices(page.map((d) => d.original)) : unselectAllInvoices()
          }
          selectedLength={selectedInvoicesUuids.length}
          checked={selectedInvoicesUuids.length > 0 && allUuids.every((d) => selectedInvoicesUuids.includes(d))}
          className="mr-4"
        />

        <Restricted permissions={['display:edit_salesfocus']}>
          <ApproveInvoicesButton setErrorMessage={setErrorMessage} />

          <DropDownButton
            variant="primary-2"
            icon="Pause"
            size="xxs"
            className="ml-2"
            dropDownMenuClassName={styles.dynamic}
            disabled={selectedInvoicesUuids.length === 0 || disabledPending || disabledIgnored}
            tooltip="sales_management.invoice_tooltip_ignore_invoice"
            noChevron
          >
            <IgnoreButton />
            <PendingButton />
          </DropDownButton>

          {(invoiceType === 'land_leasing' || direction !== 'buy') && (
            <>
              <DropDownButton
                variant="primary-2"
                icon="Send"
                size="xxs"
                className="ml-2"
                dropDownMenuClassName={styles.dynamic}
                tooltip="sales_management.invoice_tooltip_generate_final_invoice"
                data-cy="generate-final-invoices-dropdown"
                disabled={
                  // accepted_external_service are only issued through automatic process
                  acceptedExternalService.length > 0 || (disabledGenerated && disabledNomination)
                }
                noChevron
              >
                <GenerateFinalInvoicesButton invoiceType={invoiceType} />
                <SendEmailButton handleSendEmail={handleSendEmail} />
                {areAllSelectedInvoicesFrench && (
                  <>
                    {invoiceType !== 'land_leasing' && (
                      <NominateEdfOaButton toggleNominateModal={toggleNominateEdfOaModal} />
                    )}
                    {invoiceType === 'load_curve' && (
                      <NominateCegedimButton toggleNominateModal={toggleNominateCegedimModal} />
                    )}
                  </>
                )}
              </DropDownButton>

              <PaymentButton />
            </>
          )}

          <div className={styles.separator} />
        </Restricted>

        <DropDownButton
          variant="primary-2"
          icon="Download"
          size="xxs"
          dropDownMenuClassName={styles.dynamic}
          tooltip="sales_management.invoice_tooltip_download_invoice"
          disabled={selectedInvoicesUuids.length === 0}
          noChevron
          data-cy="download-invoices-dropdown"
        >
          {user.has_access_to_reports && direction !== 'buy' && (
            <button
              className={styles['menu-element']}
              onClick={() => downloadWinshuttleReportSelected.downloadWinshuttleReport()}
              disabled={selectedInvoicesUuids.length === 0}
            >
              {t('sales_management.winshuttle_report')}
            </button>
          )}

          <DownloadPdfButton textButton invoiceType={invoiceType} onError={(error) => setErrorMessage(error)} />

          <Restricted permissions={['display:time_series_details']}>
            <DownloadAuditCsvButton invoiceType={invoiceType} onError={(error) => setErrorMessage(error)} />
          </Restricted>
        </DropDownButton>

        {downloadWinshuttleReportSelected.loading && <Spinner className="mx-1" />}

        {(errorMessage ||
          downloadWinshuttleReportSelected.error?.message ||
          downloadWinshuttleReportFiltered.error?.message) && (
          <Text
            type="danger"
            className="ml-3"
            text={
              errorMessage ||
              downloadWinshuttleReportSelected.error?.message ||
              downloadWinshuttleReportFiltered.error?.message
            }
            size="sm"
          />
        )}
      </div>

      <div className="d-flex justify-content-start align-items-center">
        {(downloadWinshuttleReportFiltered.loading || downloadFecReportRequest.loading) && <Spinner className="mx-1" />}

        {showDetailsButton && (
          <Button
            data-cy="invoices-show-prod-details"
            variant="primary-2"
            size="xxs"
            className="mr-2"
            tooltip={
              isTableExpanded ? 'monitoring.invoicing.show_less_columns' : 'monitoring.invoicing.show_more_columns'
            }
            onClick={() => setIsTableExpanded?.(!isTableExpanded)}
          >
            {isTableExpanded && <Icon size={14} name="EyeOff" className="mr-2" />}
            {!isTableExpanded && <Icon size={14} name="Eye" className="mr-2" />}
            {t('common.details')}
          </Button>
        )}

        {direction !== 'buy' && (
          <DropDownButton label="common.download" icon="Download" size="xxs" variant="primary-2" position="right">
            {!user?.limited && (
              <DownloadCsvButton
                invoiceType={invoiceType}
                startDate={startDate}
                endDate={endDate}
                variant="sub-button"
                direction={direction}
              />
            )}

            {user.has_access_to_reports && (
              <button className={styles['menu-element']} onClick={handleExportRemitClick}>
                {t('common.remit')}
              </button>
            )}

            <Restricted permissions={['display:fec']}>
              <button className={styles['menu-element']} onClick={() => downloadFecReportRequest.execute?.()}>
                {t('monitoring.invoicing.fec')}
              </button>
            </Restricted>

            {user.has_access_to_reports && (
              <button
                className={styles['menu-element']}
                onClick={() => downloadWinshuttleReportFiltered.downloadWinshuttleReport()}
                disabled={filteredInvoices.length === 0}
              >
                {t('sales_management.winshuttle_report')}
              </button>
            )}
          </DropDownButton>
        )}

        {direction === 'buy' && !user?.limited && (
          <DownloadCsvButton
            invoiceType={invoiceType}
            startDate={startDate}
            endDate={endDate}
            variant="primary-2"
            direction={direction}
          />
        )}
      </div>
    </div>
  );
};
