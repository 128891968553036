import React from 'react';
import { Badge } from '@GDM/Badge';
import { CellContext } from '@tanstack/react-table';
import { FieldValues } from 'react-hook-form';

export const CollectionCell = <T extends FieldValues>({ getValue }: CellContext<T, unknown>) => {
  const value = getValue<unknown>();

  if (!Array.isArray(value) || !('name' in value[0]) || !('id' in value[0])) {
    return null;
  }

  return (
    <div className="d-flex gap-2">
      {value.map((v) => (
        <Badge key={v.id} label={`${v.name}`} />
      ))}
    </div>
  );
};
