import React, { useMemo, useState } from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { Icon, type IconNames } from '@GDM/Icon';
import { DateCell, Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { toggleFilterWithAll, arrayFilterMulti, arrayFilterMultiCollection } from '@GDM/Table/utils/filterFns';
import { useDispatchProgramSharingStatuses } from '@hooks/requests/useDispatchProgramSharingStatuses';
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type TableState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { type DispatchProgramSharingStatus as TDispatchProgram } from '@utils/types/unavailability';
import omit from 'lodash/omit';
import { useLastSelectedFilter } from 'pages/Settings/Unavailabilities/shared/filters';
import { FormProvider, type UseFormReturn } from 'react-hook-form';
import type { DispatchProgramFilters as TDispatchProgramFilters } from './DispatchProgram';
import { DispatchProgramFilters } from './DispatchProgramFilters';

export const DispatchProgramContent = ({
  filtersForm,
  filters,
}: {
  filtersForm: UseFormReturn<TDispatchProgramFilters>;
  filters: TDispatchProgramFilters;
}) => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

  const columnFilters = useMemo(() => {
    return Object.entries(omit(filters, ['last_selected_filter'])).map(([id, value]) => ({ id, value }));
  }, [filters]);

  const tableState = useMemo<Partial<TableState>>(() => {
    return {
      pagination,
      columnFilters,
      // We hide them, but we still need them for filtering purposes
      columnVisibility: { bookUuid: false, energy: false },
    };
  }, [pagination, columnFilters]);

  const columns = useMemo<ColumnDef<TDispatchProgram>[]>(
    () => [
      {
        accessorFn: (dispatch_program) => dispatch_program.installation.name,
        id: 'installationName',
        header: 'common.installation',
        cell: ({ row: { original } }) => (
          <MeterNameCell
            hasMeter={original.installation.has_meter}
            installation_name={original.installation.name}
            installation_energy={original.installation.energy}
          />
        ),
        filterFn: arrayFilterMulti,
      },
      {
        accessorKey: 'success',
        id: 'status',
        header: 'common.status_label',
        filterFn: arrayFilterMulti,
        cell: ({ row: { original } }) => {
          const value = original.success === true || original.success === false ? original.success.toString() : '';
          const props: Record<string, { name: IconNames; title: string; className: string }> = {
            '': {
              name: 'HelpCircle',
              title: 'monitoring.unavailabilities.dispatch_statuses.unknown',
              className: 'text-grey',
            },
            false: {
              name: 'AlertCircle',
              title: 'monitoring.unavailabilities.dispatch_statuses.failure',
              className: 'text-red',
            },
            true: {
              name: 'Check',
              title: 'monitoring.unavailabilities.dispatch_statuses.success',
              className: 'text-green',
            },
          };

          if (!props[value]) return null;

          return (
            <Icon size={14} name={props[value].name} title={props[value].title} className={props[value].className} />
          );
        },
      },
      {
        accessorKey: 'last_shared_time',
        header: 'monitoring.unavailabilities.last_send_date',
        cell: (row) => <DateCell getValue={row.getValue} format="DD-MM-YYYY HH:mm" />,
      },
      {
        id: 'bookUuid',
        accessorFn: (row) => row.installation?.books,
        filterFn: arrayFilterMultiCollection,
      },
      {
        accessorFn: (row) => row.installation?.energy,
        id: 'energy',
        filterFn: toggleFilterWithAll,
      },
    ],
    [],
  );

  const dispatchProgramRequest = useDispatchProgramSharingStatuses();
  const dispatchProgram = useMemo(() => dispatchProgramRequest.data ?? [], [dispatchProgramRequest]);
  const isPending = dispatchProgramRequest.isPending;

  useLastSelectedFilter(filtersForm.watch, filtersForm.setValue);

  const table = useReactTable({
    data: dispatchProgram,
    columns,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    state: tableState,
    initialState: {
      sorting: [{ id: 'installationName', desc: false }],
    },
  });

  const filteredDispatchProgram = table.getRowModel().rows.map((row) => row.original);

  return (
    <>
      <FormProvider {...filtersForm}>
        <DispatchProgramFilters
          filtersForm={filtersForm}
          filteredDispatchProgram={filteredDispatchProgram}
          dispatchProgram={dispatchProgram}
        />
      </FormProvider>

      <Table className="mt-3">
        <TableHead table={table} />
        <TableBody table={table} data-cy="dispatch-program-table-body" isLoading={isPending} />
      </Table>

      <TableActions>
        <TablePageSizeSelect
          pageSize={pagination.pageSize}
          setPageSize={table.setPageSize}
          totalEntries={dispatchProgram.length}
        />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </>
  );
};
