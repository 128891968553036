import {
  getUnavailabilities,
  type CreateUpdateUnavailabilityBody,
  createUnavailability,
  updateUnavailability,
  destroyUnavailability,
  exportUnavailabilitiesCsv,
  ExportUnavailabilitiesCsvParams,
} from '@api/unavailabilities';
import type { CreateModalForm } from '@pages/Unavailabilities/CreateModal/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { formatSystemDate } from '@utils/formatters';
import type { Unavailability } from '@utils/types/unavailability';
import fileDownload from 'js-file-download';
import type { RequestErrorBody } from '..';

export const useUnavailabilities = (params: { start_date: Date | null; end_date: Date | null }) => {
  return useQuery({
    queryKey: ['unavailabilities', params],
    queryFn: () => getUnavailabilities(params),
  });
};

export const useUnavailabilityCreateMutation = (
  params: { start_date: Date | null; end_date: Date | null },
  values: CreateModalForm,
) => {
  const queryClient = useQueryClient();

  return useMutation<Unavailability, RequestErrorBody, CreateUpdateUnavailabilityBody>({
    mutationKey: ['unavailabilities', values],
    mutationFn: (body) => createUnavailability(body),
    onSuccess: (unavailability) => {
      queryClient.setQueryData<Unavailability[]>(['unavailabilities', params], (previousUnavailabilities) => {
        const clonedUnavailabilities = previousUnavailabilities ? [...previousUnavailabilities] : [];
        clonedUnavailabilities?.push(unavailability);

        return clonedUnavailabilities;
      });
    },
  });
};

export const useUnavailabilityUpdateMutation = (
  params: { start_date: Date | null; end_date: Date | null },
  values: CreateModalForm,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    Unavailability,
    RequestErrorBody,
    { body: CreateUpdateUnavailabilityBody; uuid: Unavailability['uuid'] }
  >({
    mutationKey: ['unavailabilities', values],
    mutationFn: ({ body, uuid }) => updateUnavailability(body, uuid),
    onSuccess: (unavailability) => {
      queryClient.setQueryData<Unavailability[]>(['unavailabilities', params], (previousUnavailabilities) => {
        return previousUnavailabilities?.map((prevUnavailability) =>
          unavailability.uuid === prevUnavailability.uuid ? unavailability : prevUnavailability,
        );
      });
    },
  });
};

export const useUnavailabilityCancelMutation = (params: { start_date: Date | null; end_date: Date | null }) => {
  const queryClient = useQueryClient();

  return useMutation<Unavailability, RequestErrorBody, string>({
    mutationKey: ['unavailabilities', params],
    mutationFn: (uuid) => destroyUnavailability(uuid),
    onSuccess: (unavailability) => {
      queryClient.setQueryData<Unavailability[]>(['unavailabilities', params], (previousUnavailabilities) => {
        return previousUnavailabilities?.map((prevUnavailability) =>
          unavailability.uuid === prevUnavailability.uuid ? unavailability : prevUnavailability,
        );
      });
    },
  });
};

export const useUnavailabilityExportCsvMutation = (params: Omit<ExportUnavailabilitiesCsvParams, 'range'>) => {
  return useMutation({
    mutationKey: ['unavailabilities', params],
    mutationFn: (range: boolean) => exportUnavailabilitiesCsv({ ...params, range }),
    onSuccess: (data) => {
      fileDownload(
        data,
        `unavailabilities_${formatSystemDate(params.start_date)}_${formatSystemDate(params.end_date)}.csv`,
        'text/csv;charset=utf-8',
        '\uFEFF',
      );
    },
  });
};
