import React, { useCallback, useMemo, useState } from 'react';
import { Restricted } from '@components/Restricted';
import { useUser } from '@context/User.context';
import { DatePicker } from '@GDM/DatePicker';
import { FilterContainer } from '@GDM/Filters';
import { DataTable } from '@GDM/Table/DataTable/DataTable';
import { Toggle } from '@GDM/Toggle';
import { useUnavailabilities } from '@hooks/requests/useUnavailabilities';
import type { Unavailability } from '@utils/types/unavailability';
import dayjs from 'dayjs';
import { renderActions } from './Actions';
import { CancelModal } from './CancelModal';
import { CreateModal } from './CreateModal/CreateModal';
import { defaultValues, useUnavailabilityForm } from './CreateModal/useUnavailabilityForm';
import { ExcelUploadModal } from './ExcelUploadModal';
import { useColumns } from './getColumns';
import { renderHeader } from './Header';

export const UnavailabilitiesPageContent = () => {
  const user = useUser();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().subtract(3, 'day').toDate(),
    dayjs().add(1, 'month').toDate(),
  ]);
  const [startDate, setStartDate] = useState(dateRange[0]);
  const [endDate, setEndDate] = useState(dateRange[1]);
  const [chartVisible, setChartVisible] = useState(false);
  /**
   * This is a workaround to prevent the request from being called until the date range is valid (both dates are not null)
   */
  if (Boolean(dateRange[0] && dateRange[1]) && (startDate !== dateRange[0] || endDate !== dateRange[1])) {
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  }

  const { data: unavailabilities, isPending } = useUnavailabilities({ start_date: startDate, end_date: endDate });

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCsvModalOpen, setIsCsvModalOpen] = useState(false);
  const [selectedUnavailabilityId, setSelectedUnavailabilityId] = useState<Unavailability['uuid'] | null>(null);

  const selectedUnavailability = useMemo(
    () => unavailabilities?.find((u) => u.uuid === selectedUnavailabilityId),
    [selectedUnavailabilityId, unavailabilities],
  );

  const modalForm = useUnavailabilityForm(selectedUnavailability || null);
  const { reset } = modalForm;

  const toggleFormModal = useCallback<ToggleModal>(
    (id) => {
      setIsFormModalOpen((open) => {
        setSelectedUnavailabilityId(id);

        if (!open) {
          reset(defaultValues);
        }

        return !open;
      });
    },
    [reset],
  );

  const toggleCancelModal = useCallback<ToggleModal>((id) => {
    setIsCancelModalOpen((open) => {
      setSelectedUnavailabilityId(id);

      return !open;
    });
  }, []);

  const toggleCsvModal = useCallback(() => {
    setIsCsvModalOpen((open) => !open);
  }, []);

  const columns = useColumns({ user, onEditClick: toggleFormModal, onCancelClick: toggleCancelModal });

  return (
    <>
      <Restricted permissions={['display:unavailability']}>
        {isFormModalOpen && (
          <CreateModal
            selectedUnavailability={selectedUnavailability || null}
            isOpen={isFormModalOpen}
            toggle={toggleFormModal}
            form={modalForm}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        <CancelModal
          isOpen={isCancelModalOpen}
          toggle={toggleCancelModal}
          selectedUnavailability={selectedUnavailability || null}
          startDate={startDate}
          endDate={endDate}
        />

        <ExcelUploadModal toggle={toggleCsvModal} isOpen={isCsvModalOpen} startDate={startDate} endDate={endDate} />

        <DataTable
          id="unavailabilities"
          columns={columns}
          data={unavailabilities ?? []}
          isLoading={isPending}
          className="mt-3"
          defaultSort={{ key: 'start_date', desc: false }}
          Header={renderHeader(startDate, endDate, chartVisible)}
          ActionBar={renderActions(startDate, endDate, toggleFormModal, toggleCsvModal)}
          hasActions
          filtersPrefix={
            <FilterContainer size="datepicker">
              <DatePicker
                selectsRange
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(dates) => setDateRange(dates)}
              />
            </FilterContainer>
          }
          filtersSuffix={
            <FilterContainer>
              <Toggle
                icon="Zap"
                label="monitoring.unavailabilities.chart"
                value={chartVisible}
                onChange={(value) => setChartVisible(value)}
              />
            </FilterContainer>
          }
        />
      </Restricted>
    </>
  );
};

export type ToggleModal = (id: Unavailability['uuid'] | null) => void;
