import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { FieldValues } from 'react-hook-form';

export const StringCell = <T extends FieldValues>({
  getValue,
  I18NPrefix,
}: CellContext<T, unknown> & { I18NPrefix?: string }) => {
  const { t } = useTranslation();
  const value = getValue<string>();

  if (!value) {
    return null;
  }

  return <div className="d-flex gap-2">{t(I18NPrefix ? `${I18NPrefix}.${value}` : value)}</div>;
};
