import React from 'react';
import { Restricted } from '@components/Restricted';
import { Button, DropDownButton } from '@GDM/Button';
import { useUnavailabilityExportCsvMutation } from '@hooks/requests/useUnavailabilities';
import useTranslation from '@hooks/useTranslation';
import type { Unavailability } from '@utils/types/unavailability';
import type { ToggleModal } from './UnavailabilitiesPageContent';

export const renderActions = (
  startDate: Date | null,
  endDate: Date | null,
  toggleFormModal: ToggleModal,
  toggleCSVModal: ToggleModal,
) => {
  return ({ filteredData }: { filteredData: Unavailability[] }) => {
    const { t } = useTranslation();
    const csvRequest = useUnavailabilityExportCsvMutation({
      start_date: startDate,
      end_date: endDate,
      installations_name: Array.from(new Set(filteredData?.map((u) => u.installation.name) || [])),
    });

    return (
      <>
        <Restricted permissions={['display:edit_monitoring']}>
          <DropDownButton
            icon="Download"
            size="xxs"
            variant="primary-2"
            label={t('common.download')}
            isLoading={csvRequest.isPending}
          >
            <Button variant="sub-button" text="common.csv_range" onClick={() => csvRequest.mutate(true)} />
            <Button variant="sub-button" text="common.csv_overview" onClick={() => csvRequest.mutate(false)} />
          </DropDownButton>
          <DropDownButton icon="Plus" size="xxs" variant="primary-2" label="common.add_new">
            <Button
              variant="sub-button"
              data-cy="add-new-unavailability"
              onClick={() => toggleFormModal(null)}
              text="common.manually"
            />
            <Button
              variant="sub-button"
              data-cy="add-new-unavailability"
              onClick={() => toggleCSVModal(null)}
              text="common.excel"
            />
          </DropDownButton>
        </Restricted>
      </>
    );
  };
};
