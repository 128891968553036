import { useCallback, useMemo, useState } from 'react';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { InitialTableState, TableState } from '@tanstack/react-table';
import { PaginationState } from '@tanstack/react-table';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { FieldValues } from 'react-hook-form';
import { DataTableProps } from '../DataTable';
import { Column, FormType } from '../DataTable.types';

export function useTableState<T extends FieldValues>(
  id: DataTableProps<T>['id'],
  filters: FormType,
  columns: Column<T>[],
  pagination: PaginationState,
): UseTableStateReturn {
  const { value: localStorageColumnVisibility, set: setLocalStorageColumnVisibility } = useLocalStorage(
    `table_${id}_columns_visibility`,
  );
  const [columnVisibility, setColumnVisibility] = useState(getColumVisibility(columns, localStorageColumnVisibility));
  const [prevColumns, setPrevColumns] = useState(columns);

  if (!isEqual(columns, prevColumns)) {
    setPrevColumns(columns);
    setColumnVisibility(getColumVisibility(columns, localStorageColumnVisibility));
  }

  const columnFilters = useMemo(() => {
    return Object.entries(omit(filters, ['last_selected_filter'])).map(([id, value]) => ({ id, value }));
  }, [filters]);

  const toggleColumnVisibility = useCallback<ToggleColumnVisibility>(
    (columnId) => {
      setColumnVisibility((prev) => {
        const isDefined = prev[columnId] !== null && prev[columnId] !== undefined;
        const value = isDefined ? !prev[columnId] : false;
        const columnVisibility = { ...prev, [columnId]: value };

        setLocalStorageColumnVisibility(columnVisibility);

        return columnVisibility;
      });
    },
    [setLocalStorageColumnVisibility],
  );

  return useMemo<UseTableStateReturn>(() => {
    return {
      tableState: { pagination, columnFilters, columnVisibility } satisfies Partial<TableState>,
      actions: { toggleColumnVisibility },
    };
  }, [pagination, columnFilters, columnVisibility, toggleColumnVisibility]);
}

type UseTableStateReturn = {
  tableState: Partial<TableState>;
  actions: { toggleColumnVisibility: ToggleColumnVisibility };
};

export type ToggleColumnVisibility = (columnId: string) => void;

function getColumVisibility<T extends FieldValues>(
  columns: Column<T>[],
  localStorageColumnVisibility?: string | number | object | null,
) {
  const columnVisibilityFromConfig = columns.reduce((acc, column) => {
    if (!column.id || column.id === 'custom' || column.alwaysHide === undefined) return acc;

    acc[column.id] = !column.alwaysHide;

    return acc;
  }, {} as Record<string, boolean>);

  const columnVisibilityFromStorage: Record<string, boolean> = {};

  if (localStorageColumnVisibility && typeof localStorageColumnVisibility === 'object') {
    Object.entries(localStorageColumnVisibility).forEach(([key, value]) => {
      columnVisibilityFromStorage[key] = Boolean(value);
    });
  }

  // Config has priority, because we want to always hide some columns and never show them to the client, even if they tried to modify the localStorage
  return { ...columnVisibilityFromStorage, ...columnVisibilityFromConfig };
}

export function useInitialState<T extends FieldValues>(
  defaultSort: DataTableProps<T>['defaultSort'],
): Partial<InitialTableState> {
  return useMemo<Partial<InitialTableState>>(() => {
    if (!defaultSort) {
      return {};
    }

    return { sorting: [{ id: defaultSort.key, desc: defaultSort.desc ?? false }] };
  }, [defaultSort]);
}
