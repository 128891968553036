import { useMemo } from 'react';
import { DefaultValues, FieldValues, Path, useForm } from 'react-hook-form';
import { Column, FormType } from '../DataTable.types';

export function useFiltersForm<T extends FieldValues>(columns: Column<T>[]) {
  const columnWithFilters = useMemo(() => columns.filter((column) => column.filterType), [columns]);

  const defaultFilters = useMemo(
    () =>
      columnWithFilters.reduce(
        (acc, column) => {
          // If the column is provided a onFilter, it means it's a custom filter and we don't want to set a default value
          if (column.filterType && !column.onFilter && column.id && column.id !== 'custom') {
            const path = column.id;
            const value = (column.filterDefaultValue ??
              (column.filterType === 'multi-select' ? [] : '')) as DefaultValues<FormType>[Path<T>];

            acc[path as Path<T>] = value;
          }

          return acc;
        },
        { last_selected_filter: null } as DefaultValues<FormType>,
      ),
    [columnWithFilters],
  );
  const filtersForm = useForm<FormType>({ defaultValues: defaultFilters });

  return { filtersForm, columnWithFilters };
}
