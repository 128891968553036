import type { RequestError, RequestErrorBody } from '@hooks/useRequest';
import fetchWrapper from '@utils/fetch';
import {
  all_players_v2_market_players_path,
  intermediaries_v2_market_players_path,
  v2_buyers_path,
  linked_players_v2_market_players_path,
} from '@utils/routes';
import MarketPlayer from '@utils/types/market_player';

export const getBuyerPlayers = async (with_unavailabilities_info?: boolean) => {
  const response = await fetchWrapper(v2_buyers_path({ params: { with_unavailabilities_info } }));
  const marketPlayers: MarketPlayer[] = await response.json();

  return marketPlayers;
};

export const getLinkedMarketPlayers = async () => {
  const response = await fetch(linked_players_v2_market_players_path());
  const marketPlayers: MarketPlayer[] = await response.json();

  return marketPlayers;
};

export const getIntermediaries = async (): Promise<MarketPlayer[]> => {
  const response = await fetchWrapper(intermediaries_v2_market_players_path());

  return response.json();
};

export const getMarketPlayers = async (params?: GetMarketPlayersParams): Promise<MarketPlayer[]> => {
  // path methods require params to be an object, if undefined it will throw an error
  const response = await fetchWrapper(all_players_v2_market_players_path({ params: params || {} }));

  let json: MarketPlayer[] | RequestErrorBody;

  try {
    json = await response.json();
  } catch (error) {
    throw new Error('errors.unknown_error');
  }

  if (!response.ok) {
    const code = 'code' in json && json.code ? json.code : 'errors.unknown_error';
    const message = 'message' in json && json.message ? json.message : 'errors.unknown_error';
    const errors = 'errors' in json && json.errors ? (json.errors as RequestError[]) : [];

    throw { code, message, errors } satisfies RequestErrorBody;
  }

  return json as MarketPlayer[];
};

export type GetMarketPlayersParams = {
  book_uuid?: string;
  installation_name?: string;
  only_current?: boolean;
};
