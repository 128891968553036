import React, { useMemo } from 'react';
import { Restricted } from '@components/Restricted';
import { Badge } from '@GDM/Badge';
import { Button } from '@GDM/Button';
import { Column } from '@GDM/Table/DataTable/DataTable.types';
import { tString } from '@hooks/useTranslation';
import type { Locale } from '@utils/types/common-types';
import type { Windmill } from '@utils/types/turbine';
import { Unavailability } from '@utils/types/unavailability';
import { User } from '@utils/types/user';
import { CapacityValueCell } from './cells/CapacityValueCell';
import { EventTypeCell } from './cells/EventTypeCell';
import { SharingCell } from './cells/SharingCell';

const getColumns = ({
  onEditClick,
  onCancelClick,
  user,
}: {
  onEditClick: (id: Unavailability['uuid'] | null) => void;
  onCancelClick: (id: Unavailability['uuid'] | null) => void;
  user: User;
}): Column<Unavailability>[] => {
  const columns: Column<Unavailability>[] = [
    {
      id: 'installation_name',
      header: 'common.installation',
      accessorKey: 'installation.name',
      dataType: 'installation',
      filterType: 'multi-select',
    },
    {
      id: 'installation_books',
      header: 'common.book',
      accessorKey: 'installation.books',
      dataType: 'book',
      filterType: 'multi-select',
      alwaysHide: true,
    },
    {
      id: 'windmills',
      header: 'common.wind.turbine',
      accessorKey: 'windmills',
      dataType: 'collection',
      filterType: 'multi-select',
      isHidable: true,
      customCell: ({ row }) => {
        return (
          <div className="d-flex gap-2">
            {row.original.windmills?.map((windmill) => (
              <Badge key={windmill.id} tooltip={getWindmillTooltip(windmill, user.locale)}>
                {windmill.name}
              </Badge>
            ))}
          </div>
        );
      },
    },
    {
      id: 'start_date',
      header: 'common.date',
      accessorKey: 'start_date',
      dataType: 'daterange',
      isHidable: true,
    },
    {
      id: 'installation_energy',
      header: 'common.energy',
      accessorKey: 'installation.energy',
      dataType: 'energy',
      filterType: 'radio',
      filterDefaultValue: 'all',
      alwaysHide: true,
    },
    {
      id: 'real_capacity_value',
      header: 'monitoring.unavailabilities.capacity_value',
      tooltip: 'MW',
      customCell: CapacityValueCell,
      accessorKey: 'real_capacity_value',
      dataType: 'number',
      isHidable: true,
    },
    {
      id: 'p_max',
      header: 'monitoring.unavailabilities.p_max',
      tooltip: 'MW',
      accessorKey: 'p_max',
      dataType: 'number',
      isHidable: true,
    },
    {
      id: 'source',
      header: 'monitoring.invoicing.source',
      dataType: 'string',
      accessorKey: 'source',
      filterType: 'multi-select',
      I18NPrefix: 'monitoring.unavailabilities.sources',
      isHidable: true,
    },
    {
      id: 'event_type',
      header: 'monitoring.unavailabilities.event_type',
      customCell: EventTypeCell,
      dataType: 'string',
      accessorKey: 'event_type',
      filterType: 'multi-select',
      I18NPrefix: 'monitoring.unavailabilities',
      isHidable: true,
    },
    {
      id: 'impact_type',
      dataType: 'string',
      accessorKey: 'impact_type',
      alwaysHide: true,
      filterType: 'multi-select',
      header: 'monitoring.unavailabilities.impact_type.label',
      I18NPrefix: 'monitoring.unavailabilities.impact_type',
    },
    {
      id: 'comment',
      header: 'monitoring.unavailabilities.comment',
      accessorKey: 'comment',
      dataType: 'string',
      isHidable: true,
    },
    {
      id: 'counterpart_name',
      header:
        user.account_type == 'aggregator'
          ? 'monitoring.unavailabilities.producer'
          : 'monitoring.unavailabilities.aggregator',
      tooltip: 'monitoring.unavailabilities.warnings.counterpart_null_case',
      accessorKey: 'counterpart_name',
      dataType: 'string',
      isHidable: true,
    },
    {
      id: 'sharing_status',
      header: 'monitoring.unavailabilities.sharing_status',
      customCell: SharingCell,
      dataType: 'string',
      filterType: 'multi-select',
      accessorKey: 'sharing_status',
      I18NPrefix: 'monitoring.unavailabilities.sharing_statuses',
      isHidable: true,
    },
    {
      id: 'status',
      header: 'common.status_label',
      dataType: 'string',
      filterType: 'multi-select',
      accessorKey: 'status',
      I18NPrefix: 'monitoring.unavailabilities.status',
      isHidable: true,
    },
    {
      id: 'updated_at',
      header: 'common.updated_at',
      accessorKey: 'updated_at',
      dataType: 'datetime',
      isHidable: true,
    },
    {
      id: 'custom',
      header: '',
      dataType: 'action',
      customCell: ({ row }) => (
        <Restricted permissions={['display:edit_monitoring']}>
          <Button
            className="ml-2"
            onClick={() => onEditClick(row.original.uuid)}
            disabled={row.original.sharing_status === 'nominated_to_send' || row.original.sharing_status === 'sending'}
            icon="Edit3"
            variant="primary-2"
            floating
          />
          <Button
            className="ml-2"
            onClick={() => onCancelClick(row.original.uuid)}
            disabled={row.original.sharing_status === 'nominated_to_send' || row.original.sharing_status === 'sending'}
            icon="Trash2"
            variant="secondary"
            floating
          />
        </Restricted>
      ),
    },
  ];

  return columns;
};

export const useColumns = ({
  onEditClick,
  onCancelClick,
  user,
}: {
  onEditClick: (id: Unavailability['uuid'] | null) => void;
  onCancelClick: (id: Unavailability['uuid'] | null) => void;
  user: User;
}) => {
  return useMemo(() => getColumns({ onEditClick, onCancelClick, user }), [onEditClick, onCancelClick, user]);
};

const getWindmillTooltip = (windmill: Windmill, locale: Locale) => {
  const t = tString(locale);

  const aggregTooltip = windmill.ref_aggregator
    ? `${windmill.ref_aggregator && `${t('monitoring.turbines.ref_aggregator')}: ${windmill.ref_aggregator}<br/>`}`
    : '';

  const manufacturerTooltip = windmill.ref_manufacturer
    ? `${windmill.ref_manufacturer && `${t('monitoring.turbines.ref_manufacturer')}: ${windmill.ref_manufacturer}`}`
    : '';

  return `${aggregTooltip}${manufacturerTooltip}`;
};
