import React, { Dispatch, SetStateAction, memo, useState } from 'react';
import ControlledInput from '@components/FormInputs/ControlledInput';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { DropDownButton } from '@GDM/Button';
import { FilterContainer, Filters, useDynamicOptions } from '@GDM/Filters';
import { Checkbox } from '@GDM/forms';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  TableState,
  InitialTableState,
  ColumnDef,
  PaginationState,
} from '@tanstack/react-table';
import { useLastSelectedFilter } from 'pages/Settings/Unavailabilities/shared/filters';
import { FieldValues, FormProvider, Path, useFormContext, useWatch } from 'react-hook-form';
import { Table } from '../Table';
import { TableActions } from '../TableActions';
import { TableBody } from '../TableBody';
import { TableHead } from '../TableHead';
import { TablePageSizeSelect } from '../TablePageSizeSelect';
import { TablePagination } from '../TablePagination';
import { Column, FormType } from './DataTable.types';
import { useFiltersForm } from './hooks/useFiltersForm';
import { useGetOptions } from './hooks/useGetOptions';
import { useInitialState, useTableState, type ToggleColumnVisibility } from './hooks/useTableState';
import { useColumns } from './useColumns';

export function DataTable<T extends FieldValues>({
  columns,
  data,
  className,
  isLoading,
  Header,
  ActionBar,
  defaultSort,
  filtersPrefix,
  filtersSuffix,
  hasActions = false,
  id,
}: DataTableProps<T>) {
  const pagination = useState({ pageIndex: 0, pageSize: 25 });
  const { filtersForm, columnWithFilters } = useFiltersForm(columns);
  const filters = useWatch({ control: filtersForm.control }) as FormType; // useWatch is not inferring the type FormType
  const internalColumns = useColumns(columns);
  const initialState = useInitialState(defaultSort);
  const {
    tableState,
    actions: { toggleColumnVisibility },
  } = useTableState(id, filters, columns, pagination[0]);

  const hidableColumns = columns.filter((column) => column.isHidable);

  useLastSelectedFilter(filtersForm.watch, filtersForm.setValue);

  return (
    <div className={className}>
      <DataTableContent
        isLoading={isLoading}
        filters={filters}
        columns={internalColumns}
        data={data}
        hasActions={hasActions}
        initialState={initialState}
        tableState={tableState}
        pagination={pagination}
        renderHeader={(filteredData) => (
          <>
            {columnWithFilters.length > 0 && (
              <FormProvider {...filtersForm}>
                <Filters onClear={() => filtersForm.reset()}>
                  {filtersPrefix}
                  {columnWithFilters.map((column) => (
                    <MemoizedFilterInput key={column.id} column={column} data={data} filteredData={filteredData} />
                  ))}
                  {filtersSuffix}
                </Filters>
              </FormProvider>
            )}

            {Header && <Header filteredData={filteredData} allData={data} filters={filters} />}

            <div className="d-flex gap-2 p-3 align-items-center justify-content-end hide-empty">
              {hidableColumns.length > 0 && (
                <HideColumnsButton
                  hidableColumns={hidableColumns}
                  columnVisibility={tableState.columnVisibility}
                  toggleColumnVisibility={toggleColumnVisibility}
                />
              )}
              {ActionBar && <ActionBar filteredData={filteredData} allData={data} filters={filters} />}
            </div>
          </>
        )}
      />
    </div>
  );
}

function DataTableContent<T extends FieldValues>({
  columns,
  data,
  isLoading,
  hasActions,
  initialState,
  renderHeader,
  tableState,
  pagination,
}: {
  isLoading?: boolean;
  filters: FormType;
  columns: ColumnDef<T>[];
  data: T[];
  hasActions: boolean;
  initialState: InitialTableState;
  renderHeader: (filteredData: T[]) => React.ReactNode;
  tableState: Partial<TableState>;
  pagination: [PaginationState, Dispatch<SetStateAction<PaginationState>>];
}) {
  const [{ pageSize }, setPagination] = pagination;
  /**
   * WARNING: Table declaration must never be at the same level as filtersForm.watch()
   * as it will cause a re-render loop, that's why we declare the DataTableContent in DataTable
   */
  const table = useReactTable({
    data,
    columns,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    state: tableState,
    initialState,
  });

  const filteredData = table.getFilteredRowModel().flatRows.map((row) => row.original);

  return (
    <>
      {renderHeader(filteredData)}

      <Table hasActions={hasActions}>
        <TableHead table={table} />
        <TableBody table={table} data-cy="dispatch-program-table-body" isLoading={isLoading} />
      </Table>

      <TableActions>
        <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </>
  );
}

const MemoizedFilterInput = memo(FilterInput) as <T extends FieldValues>(props: FilterInputProps<T>) => React.ReactNode;

function FilterInput<T extends FieldValues>({ column, data, filteredData }: FilterInputProps<T>) {
  const { control } = useFormContext<T>();
  const name = column.id as Path<T>;

  const getOptions = useGetOptions(column);

  const options = useDynamicOptions(getOptions, name, filteredData, data);

  const placeholder = column.header;

  if (column.filterType && ['multi-select', 'single-select'].includes(column.filterType)) {
    return (
      <FilterContainer size={column.dataType === 'installation' || column.dataType === 'book' ? 'select' : 'fit'}>
        <ControlledSelect
          options={options}
          name={name}
          control={control}
          isMulti={column.filterType === 'multi-select'}
          placeholder={placeholder}
          inline
          isInstallationOrBook={column.dataType === 'installation' || column.dataType === 'book'}
        />
      </FilterContainer>
    );
  }

  if (column.filterType === 'radio') {
    return (
      <FilterContainer size="fit">
        <ControlledRadioButtons name={name} control={control} options={options} />
      </FilterContainer>
    );
  }

  if (column.filterType === 'search') {
    return (
      <FilterContainer>
        <ControlledInput name={name} control={control} />
      </FilterContainer>
    );
  }
}

function HideColumnsButton<T extends FieldValues>({
  hidableColumns,
  columnVisibility,
  toggleColumnVisibility,
}: {
  hidableColumns: Column<T>[];
  columnVisibility?: TableState['columnVisibility'];
  toggleColumnVisibility: ToggleColumnVisibility;
}) {
  return (
    <DropDownButton
      icon="Settings"
      size="xxs"
      variant="primary-2"
      noChevron
      tooltip="contracts.table.show_or_hide_columns"
      position="right"
    >
      <div className="p-2 d-flex flex-column gap-1">
        {hidableColumns.map((column) => {
          const isDefined = columnVisibility?.[column.id] !== undefined && columnVisibility?.[column.id] !== null;
          const checked = isDefined ? columnVisibility?.[column.id] : true;

          return (
            <Checkbox
              key={column.id}
              label={column.header}
              checked={checked}
              onChange={() => toggleColumnVisibility(column.id)}
            />
          );
        })}
      </div>
    </DropDownButton>
  );
}

export type DataTableProps<T extends FieldValues> = {
  id: string;
  columns: Column<T>[];
  data: T[];
  className?: string;
  isLoading?: boolean;
  /*
   * ActionBar is used to display an action bar above the table (download, export, create...etc)
   */
  ActionBar?: (props: { filteredData: T[]; allData: T[]; filters: FormType }) => React.ReactNode;
  Header?: (props: { filteredData: T[]; allData: T[]; filters: FormType }) => React.ReactNode;
  defaultSort?: { key: Path<T>; desc?: boolean };
  filtersPrefix?: React.ReactNode;
  filtersSuffix?: React.ReactNode;
  hasActions?: boolean;
};

type FilterInputProps<T extends FieldValues> = {
  column: Column<T>;
  data: T[];
  filteredData: T[];
};
