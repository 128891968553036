import React from 'react';
import { importXLSX } from '@api/unavailabilities';
import { Button } from '@GDM/Button';
import { HelpBox } from '@GDM/HelpBox';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import { UploadInput } from '@GDM/UploadInput/UploadInput';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Unavailability } from '@utils/types/unavailability';
import { useForm } from 'react-hook-form';

export const ExcelUploadModal = ({
  toggle,
  isOpen,
  startDate,
  endDate,
}: {
  toggle: () => void;
  isOpen: boolean;
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { set: setShowHelpLocalStorage, value: showHelpLocalStorage } = useLocalStorage('UNAVAILABILITIES_SHOW_HELP');
  const form = useForm<ImportFormType>();
  const queryClient = useQueryClient();

  const importMutation = useMutation({ mutationKey: ['import-unavailabilities-xlsx'], mutationFn: importXLSX });

  const handleDownloadExample = () => {
    window.open('/example_unavailabilities.xlsx', '_blank');
  };

  const handleSubmit = (data: ImportFormType) => {
    if (!data.xlsx) return;

    importMutation.mutate(data.xlsx[0], {
      onSuccess: (unavailabilities) => {
        const params = { start_date: startDate, end_date: endDate };

        queryClient.setQueryData<Unavailability[]>(['unavailabilities', params], (previousUnavailabilities) => {
          const clonedUnavailabilities = previousUnavailabilities ? [...previousUnavailabilities] : [];

          clonedUnavailabilities?.push(...unavailabilities);

          toggle();

          return clonedUnavailabilities;
        });
      },
    });
  };

  const showHelp = Boolean(showHelpLocalStorage);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle()}
      header="common.import_excel"
      submitAction={form.handleSubmit(handleSubmit)}
      isLoading={importMutation.isPending}
      error={importMutation.error?.message}
      footer={
        <Button
          text="monitoring.unavailabilities.xlsx_upload.download_example"
          icon="Download"
          variant="link"
          size="sm"
          className="mr-auto"
          onClick={handleDownloadExample}
        />
      }
    >
      {!showHelp && (
        <Button
          icon="HelpCircle"
          text="common.show_help"
          size="sm"
          variant="link"
          onClick={() => setShowHelpLocalStorage(1)}
          className="mb-3"
        />
      )}

      {showHelp && (
        <HelpBox className="mb-3" dismissible onDismiss={() => setShowHelpLocalStorage(0)}>
          <Text text="monitoring.unavailabilities.xlsx_upload.info" multiline />

          <br />

          <Text text="monitoring.unavailabilities.xlsx_upload.header" multiline />

          <br />

          <Text text="monitoring.unavailabilities.xlsx_upload.info_event_type" multiline />
          <Text text="monitoring.unavailabilities.xlsx_upload.events" multiline />

          <br />

          <Text text="monitoring.unavailabilities.xlsx_upload.info_2" multiline />
        </HelpBox>
      )}

      <UploadInput fileTypes={['.xlsx']} {...form.register('xlsx', { required: true })} />
    </Modal>
  );
};

type ImportFormType = { xlsx: FileList | null };
